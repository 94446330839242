import React, { useState } from 'react';
import './ContactUs.css';
import Header from '../home/Header';
import { toast } from 'react-toastify';

const ContactUs = () => {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const [submitted, setSubmitted] = useState(false);
    const [isLoading, setIsLoading] = useState(false); // To show loading spinner

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true); // Show loading spinner
        try {
            const server = process.env.REACT_APP_API_SERVER;
            const username = process.env.REACT_APP_API_USERNAME;
            const password = process.env.REACT_APP_API_PASSWORD;
            const credentials = btoa(`${username}:${password}`);

            const response = await fetch(`${server}/contact`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Basic ${credentials}`,
                },
                body: JSON.stringify({
                    name: name,
                    email: email,
                    message: message,
                }),
            });
            if (response.ok) {
                toast.success("Message sent successfully.");
                setSubmitted(true);
                setName('');
                setEmail('');
                setMessage('');
            } else {
                const errorMsg = await response.text();
                switch (response.status) {
                    case 400:
                        toast.error(errorMsg || 'Bad Request: Please check your input.');
                        break;
                    case 401:
                        toast.error(errorMsg || 'Unauthorized: Access is denied.');
                        break;
                    case 403:
                        toast.error(errorMsg || 'Forbidden: You do not have permission.');
                        break;
                    case 500:
                        toast.error(errorMsg || 'Internal Server Error: Please try again later.');
                        break;
                    default:
                        toast.error(errorMsg || 'An unexpected error occurred.');
                        break;
                }
            }
        } catch (error) {
            console.log(error);
            toast.error(error.message);
        } finally {
            setIsLoading(false); // Hide loading spinner after request is done
        }
    };

    return (
        <>
            <Header />

            <div className="contact-us-container">
                <h2>Contact Us</h2>
                <form onSubmit={handleSubmit} className="contact-form">
                    <div className="form-group">
                        <label htmlFor="name">Name</label>
                        <input
                            type="text"
                            id="name"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            placeholder="Your name"
                            required
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="email">Email</label>
                        <input
                            type="email"
                            id="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            placeholder="Your email"
                            required
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="message">Message</label>
                        <textarea
                            id="message"
                            value={message}
                            onChange={(e) => setMessage(e.target.value)}
                            placeholder="Your message"
                            required
                        />
                    </div>
                    <button type="submit" className="submit-button">
                        {isLoading ? (
                            <span className="loading-spinner"></span>
                        ) : (
                            'Submit'
                        )}                        </button>
                    {submitted && <p className="submit-status">Your message has been sent!</p>}
                </form>
            </div>
        </>
    );
};

export default ContactUs;
