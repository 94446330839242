import React, { useEffect } from 'react'
import Header from '../home/Header'
import { useNavigate } from 'react-router-dom';

const Pricing = () => {
    const email = sessionStorage.getItem('user_email');
    const pricingTableId = process.env.REACT_APP_STRIPE_PRICING_TABLE_ID;
    const publishableKey = process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY;
    const navigate = useNavigate();

    useEffect(() => {
        if (!email) {
            navigate('/login');
            return;

        }
    }, [email]);

    return (
        <div className='pricing-page'>
            <Header />

            <h1>Pricing</h1>
            <stripe-pricing-table
                pricing-table-id={pricingTableId}
                publishable-key={publishableKey}
                customer-email={email}
            >
            </stripe-pricing-table>

        </div>
    )
}

export default Pricing