import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import './Login.css';

const Login = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    /**
     * Login logic
     * @param {*} e 
     */

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        try {
            const server = process.env.REACT_APP_API_SERVER;
            const response = await fetch(`${server}/login`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    email: email,
                    password: password,
                }),
            });
            // The fetch API considers responses with status codes in the range 200-299 as successful.
            if (response.ok) {
                sessionStorage.setItem('user_email', email);
                // Redirect to the verified home page
                navigate('/');
            } else {
                // Login failed, display an error message.
                if (response.status === 401) {
                    toast.error('Email and password do not match.');
                } else if (response.status === 403) {
                    toast.warning('User not verified. Please check your email and verify.');
                } else if (response.status === 404) {
                    toast.warning('User not found.');
                } else {
                    console.error(
                        `An error occurred during authentication: ${response.message}`
                    );
                    toast.error('An error occurred during authentication.');
                }
            }
        } catch (error) {
            console.error('Unexpected error:', error);
            toast.error(`An unexpected error occurred: ${error.message}`);
        } finally {
            setLoading(false);
        }
    };
    return (
        <div className="login-container">
            {/* <h2>Login</h2> */}
            <form className='login-form' onSubmit={handleSubmit}>
                {/* <img src={logoFM} alt='Logo' className='login-logo-image' /> */}
                <h1 className='form__title'>GeoQuery Service</h1>
                {/* <h2>Login</h2> */}
                <div>
                    <label>Email:</label>
                    <input
                        type="text"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                    />
                </div>
                <div>
                    <label>Password:</label>
                    <input
                        type="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        required
                    />
                </div>
                <button className='form__button' type='submit' disabled={loading}>
                    {loading ? 'Loading...' : 'Login'}
                </button>
                <p className='form__text'>
                    <a href='/reset' className='form__link' id='linkResetAccount'>
                        Forgot your Password?
                    </a>
                </p>
                <p className='form__text signup-text'>
                    Don't have an account? <a href='/signup' className='form__link'>Sign up</a>
                </p>
            </form>
        </div>
    );
};

export default Login;

