import React from 'react'
import Header from './Header'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTruck, faNewspaper, faGraduationCap, faDatabase, faWifi, faImage, faTruckMoving, faMicrochip, faMoneyBillWave } from '@fortawesome/free-solid-svg-icons';
import './home.css';
import { Link } from 'react-router-dom';

const Home = () => {
  const email = sessionStorage.getItem("user_email");
  const industries = [
    { name: 'Fleet Tracking', icon: faTruck },
    { name: 'Classifieds', icon: faNewspaper },
    { name: 'Academia', icon: faGraduationCap },
    { name: 'Data Enrichment', icon: faDatabase },
    { name: 'Internet of Things', icon: faWifi },
    { name: 'Image Processing', icon: faImage },
    { name: 'Logistics', icon: faTruckMoving },
    { name: 'Micromobility', icon: faMicrochip },
    { name: 'Payment Processing', icon: faMoneyBillWave }
  ];
  return (
    <div>
      <Header />
      <div className="service-info">
        <h2>What Is GeoQuery Service?</h2>
        <p>
          GeoQuery Serviece is a robust location platform designed to simplify the development of maps, optimize routes, and create intelligent, location-based applications. Our platform offers a suite of essential tools, including core maps APIs, and comprehensive data services, all tailored to streamline geospatial analytics and application development.
        </p>
        <p>
          We prioritize ease of use and reliability, ensuring that our APIs deliver accurate, up-to-date location data you can trust. With our competitive pricing, you can focus on building your project without the constraints of budget concerns.
        </p>
        <h2>Who builds Geoquery Service? And why?</h2>
        <p>
          <strong>Our mission:</strong> to make using open data easy, dependable, and much more affordable than proprietary geocoders.
        </p>
        <p>
          Learn about the advantages of open data, the team behind the API, and how we give back to the open geospatial community.
        </p>
      </div>
      <div className="industry-usage">
        <h2>See how Geoquery is used in your industry</h2>
        <ul>
          {industries.map((industry, index) => (
            <li key={index}>
              <FontAwesomeIcon icon={industry.icon} className="industry-icon" />
              {industry.name}
            </li>
          ))}
        </ul>
      </div>
      <div className="cta-section">
        <h3>Ready to Get Started?</h3>
        <p>Start building your location-based solutions today with GeoQuery Service. Sign up for an account and explore the API.</p>
        <Link to="/signup" className="cta-button">Sign Up</Link>
      </div>

    </div>
  )
}

export default Home