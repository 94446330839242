import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import './Reset.css';

const Reset = () => {
    const [email, setEmail] = useState('');
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        try {
            const server = process.env.REACT_APP_API_SERVER;
            // const server = "http://localhost:4567"
            const response = await fetch(`${server}/reset`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ email }),
            });
            if (response.ok) {
                toast.success('Password reset link has been sent to your email.');
                navigate('/login');
            } else {
                const errorData = await response.json();
                toast.error(errorData.message || 'An error occurred during password reset.');
            }
        } catch (error) {
            console.error('Unexpected error:', error);
            toast.error(error.message);
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="reset-container">
            <form className='reset-form' onSubmit={handleSubmit}>
                <h1 className='form__title'>Reset Password</h1>
                <div>
                    <label>Email:</label>
                    <input
                        type="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                    />
                </div>
                <button className='form__button' type='submit' disabled={loading}>
                    {loading ? 'Loading...' : 'Reset Password'}
                </button>
                <p className='form__text'>
                    Remembered your password? <a href='/login' className='form__link'>Login</a>
                </p>
            </form>
        </div>
    )
}

export default Reset