import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import './Header.css';
import { toast } from 'react-toastify';
import logo from '../../assets/logo_transparent-c.png'

const Header = () => {
    const [menuOpen, setMenuOpen] = useState(false);
    const email = sessionStorage.getItem('user_email');
    const navigate = useNavigate();

    const handleLogout = () => {
        sessionStorage.removeItem('user_email');
        toast.success("Logout successful!");
        navigate("/login");
    };

    const toggleMenu = () => {
        setMenuOpen(!menuOpen);
    };

    return (
        <div className="header">
            <nav className="navbar">
                <div className="logo-container">
                    <Link to="/">
                        <img src={logo} alt="GeoQuery Data Logo" className="logo" />
                    </Link>
                </div>
                <div className="hamburger"
                    onClick={toggleMenu}
                    aria-label="Toggle navigation menu">
                    <div className="line"></div>
                    <div className="line"></div>
                    <div className="line"></div>
                </div>
                <ul className={`menu ${menuOpen ? 'open' : ''}`}>
                    <li><Link to="/">Home</Link></li>
                    <li><Link to="/api-doc">API Documentation</Link></li>
                    {email && <li><Link to="/my-api-keys">My API Keys</Link></li>}
                    <li><Link to="/about">About</Link></li>
                    <li><Link to="/contact">Contact</Link></li>
                    {email ? (
                        <li><Link to="/pricing">Pricing</Link>
                        </li>
                    ) : (
                        <li><Link to="/op-pricing">Pricing</Link>
                        </li>
                    )}
                    {/* <li><Link to="/pricing">Pricing</Link></li> */}
                    {email && <li><Link to="/my-profile">My Profile</Link></li>}
                    <div className="auth-buttons-mobile">
                        {email ? (
                            <button onClick={handleLogout} className="auth-button">Logout</button>
                        ) : (
                            <>
                                <Link to="/login" className="auth-button">Login</Link>
                                <Link to="/signup" className="auth-button">Signup</Link>
                            </>
                        )}
                    </div>
                </ul>
            </nav>
        </div>
    );
}

export default Header;
