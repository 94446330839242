import React from 'react';
import SwaggerUI from 'swagger-ui-react';
import 'swagger-ui-react/swagger-ui.css';
import geoqueryapi from './geoqueryapi.json';
import Header from '../home/Header';

const ApiDocumentation = () => {
    return (
        <div>
                  <Header/>

            <SwaggerUI spec={geoqueryapi} />
        </div>
    );
};

export default ApiDocumentation;