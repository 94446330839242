import React from 'react';
import './PrivacyPolicy.css';
import Header from '../home/Header';

const PrivacyPolicy = () => {
    return (
        <div>
            <Header />
            <div className='privacy-policy-container'>
                <h1>Privacy Policy</h1>

                <p>
                    At Geoquery Data, we respect your privacy and are committed to protecting your personal information.
                    This privacy policy explains how we collect, use, and share information when you use our website and
                    services, including our geocoding services that require a subscription.
                </p>

                <h2>1. Information We Collect</h2>
                <ul>
                    <li>
                        <strong>Personal Information:</strong> When you register or subscribe to our services, we may collect personal information,
                        such as your name, email address, payment details, and any other details you provide.
                    </li>
                    <li>
                        <strong>Usage Data:</strong> We collect information on how you interact with our services, including IP addresses, device information,
                        and geolocation data.
                    </li>
                    <li>
                        <strong>Payment Information:</strong> Payment processing is handled through Stripe, and we do not store credit card details.
                        Stripe may collect your payment information to process subscriptions.
                    </li>
                </ul>

                <h2>2. How We Use Your Information</h2>
                <ul>
                    <li>
                        <strong>Provide Services:</strong> We use your information to provide and improve our geocoding services, including handling subscription management and limiting API usage as per your subscription plan.
                    </li>
                    <li>
                        <strong>Payment Processing:</strong> Payment details are used solely for processing subscription fees via Stripe.
                    </li>
                    <li>
                        <strong>Communication:</strong> We may use your email to send updates, notifications, or support-related messages.
                    </li>
                </ul>

                <h2>3. Sharing of Information</h2>
                <ul>
                    <li>
                        <strong>Payment Processors:</strong> We share information with payment processors like Stripe for transaction processing.
                    </li>
                    <li>
                        <strong>Service Providers:</strong> We may share data with third-party service providers who help us maintain and improve our services.
                    </li>
                    <li>
                        <strong>Legal Compliance:</strong> We may disclose your information to comply with applicable legal obligations.
                    </li>
                </ul>

                <h2>4. Security of Your Information</h2>
                <p>
                    We take reasonable measures to protect your data, but no internet transmission is completely secure. We encourage you to take appropriate steps to protect your information.
                </p>

                <h2>5. Your Rights</h2>
                <ul>
                    <li>
                        <strong>Access and Correction:</strong> You can access and update your personal information by logging into your account.
                    </li>
                    <li>
                        <strong>Deletion:</strong> You can request the deletion of your data by contacting us.
                    </li>
                    <li>
                        <strong>Withdrawal of Consent:</strong> You may withdraw your consent for us to use your information by cancelling your subscription.
                    </li>
                </ul>

                <h2>6. Use of Cookies</h2>
                <p>
                    We use cookies and similar tracking technologies to enhance your experience on our website.
                </p>

                <h2>7. Reverse Geocoding API Service Liability Policy</h2>
                <h3>Disclaimer of Liability</h3>
                <p>
                    Our Reverse Geocoding API service is designed to provide accurate and timely location data; however, we cannot guarantee the completeness or accuracy of the information provided. By using our service, you acknowledge and agree that we are not liable for any damages, losses, or expenses that may arise from the use of the data obtained through our API. This includes, but is not limited to, direct, indirect, incidental, consequential, or punitive damages.
                </p>

                <h3>Support and Remediation</h3>
                <p>
                    While we accept no liability for damages resulting from the use of our service, we are committed to assisting users in resolving any issues they may encounter. If you experience difficulties or discrepancies in the data provided, please contact our support team. We will make reasonable efforts to address your concerns and work towards a resolution.
                </p>

                <h3>Acknowledgment</h3>
                <p>
                    By accessing and using our Reverse Geocoding API, you acknowledge that you have read, understood, and agree to this liability policy. You also agree to indemnify and hold harmless our company from any claims arising from your use of the service.
                </p>

                <h2>8. Changes to This Privacy Policy</h2>
                <p>
                    We may update this policy occasionally. The latest version will always be posted on our website.
                </p>

                <h2>9. Contact Us</h2>
                <p>
                    If you have any questions or concerns about this privacy policy, please feel free to <a href="/contact">contact us</a>.
                </p>
            </div>

        </div>

    );
}

export default PrivacyPolicy;
