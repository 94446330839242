import React from 'react';
import './AboutUs.css';
import Header from '../home/Header';

const AboutUs = () => {
    return (
        <>
            <Header />

            <div className="about-us-container">
                <h2>About Geoquery Service</h2>
                <p>
                    Welcome to Geoquery, your go-to service for reverse geocoding. Our mission is to provide accurate and efficient geocoding services to help you transform geographic coordinates into meaningful addresses.
                </p>
                <p>
                    Whether you're a developer looking to integrate geocoding into your application or a business needing reliable location data, Geoquery has you covered. Our API is designed to be easy to use, fast, and reliable, making it the perfect choice for your geocoding needs.
                </p>
                <h3>Our Vision</h3>
                <p>
                    At Geoquery, we envision a world where location data is accessible, actionable, and transformative for every business and individual.
                    Our commitment is to empower decision-making through precise geospatial insights.
                </p>
                <h3>Why Choose Geoquery?</h3>
                <ul>
                    <li>Unmatched accuracy with our cutting-edge algorithms.</li>
                    <li>Flexible pricing plans to suit projects of all sizes.</li>
                    <li>Dedicated support team ready to assist 24/7.</li>
                    <li>Seamless integration with popular frameworks and platforms.</li>
                </ul>

                <h3>Our Features</h3>
                <ul>
                    <li>Accurate reverse geocoding</li>
                    <li>High performance and scalability</li>
                    <li>Simple and intuitive API</li>
                    <li>Comprehensive documentation</li>
                </ul>
                <p>
                    Thank you for choosing Geoquery. If you have any questions or need support, feel free to <a href="/contact">contact us</a>.
                </p>

            </div>
        </>
    );
};

export default AboutUs;
