import React, { useEffect, useState } from 'react'
import KeyGenerator from '../../components/home/KeyGenerator'
import './MyAPIKeys.css';
import Header from '../home/Header';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

const MyAPIKeys = () => {
    const [apiKeys, setApiKeys] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');

    const username = process.env.REACT_APP_API_USERNAME;
    const password = process.env.REACT_APP_API_PASSWORD;
    const credentials = btoa(`${username}:${password}`);
    const navigate = useNavigate();
    const email = sessionStorage.getItem("user_email");

    useEffect(() => {
        if (!email) {
            navigate('/login');
            return;

        }

        fetchApiKeys();
    }, [credentials, email, navigate]);

    const fetchApiKeys = async () => {
        setLoading(true);
        try {
            const response = await fetch(`${process.env.REACT_APP_API_SERVER}/api/keys/${email}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Basic ${credentials}`,

                },
            });
            if (response.ok) {
                const data = await response.json();
                console.log("Api keys: ", data)
                setApiKeys(data);
            } else if (response.status === 404) {
                setError('No API keys found for this user.');
            } else {
                setError('Failed to fetch API keys.');
            }
        } catch (error) {
            setError('An unexpected error occurred.');
        } finally {
            setLoading(false);
        }
    };

    const refreshApiKeys = async () => {
        setLoading(true);
        try {
            const response = await fetch(`${process.env.REACT_APP_API_SERVER}/api/keys/${email}`,
                {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Basic ${credentials}`,
                    },
                });
            if (response.ok) {
                const data = await response.json();
                setApiKeys(data);
            } else if (response.status === 404) {
                setError('No API keys found for this user.');
            } else {
                setError('Failed to fetch API keys.');
            }
        } catch (error) {
            setError('An unexpected error occurred.');
        } finally {
            setLoading(false);
        }
    };
    /**
     * Delete an API key
     * @param {*} key 
     */
    const deleteApiKey = async (key) => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_SERVER}/api/keys/${email}?key=${key}`,
                {
                    method: 'DELETE',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Basic ${credentials}`,
                    },
                });
            if (response.ok) {
                toast.success("API key deleted successfully.")
                refreshApiKeys();
            } else if (response.status === 404) {
                toast.error("Api key not found in db.");
            } else {
                toast.error('Failed to delete the API key.')
            }
        } catch (error) {
            toast.error('An unexpected error occurred while deleting the API key.');
        }
    };

    return (
        <>
            <Header />
            <div className="my-api-keys-container">
                {/* <h1>My API Keys</h1> */}
                {loading ? (
                    <p>Loading API Keys...</p>
                ) : error ? (
                    <p className='error-message'> {error}</p>
                ) : (
                    <div className="api-keys-list">
                        <h2>Your API keys:</h2>
                        <ul>
                            {apiKeys.map((keyDetail, index) => (

                                <li className="api-key-item" key={index}>
                                    <span className="api-key">{keyDetail.key}</span>
                                    <span className="api-key-date"> Created on {keyDetail.addedOn}</span>
                                    <button className='delete-button' onClick={() => deleteApiKey(keyDetail.key)}>Delete</button>
                                </li>
                            ))}
                        </ul>
                    </div>
                )}
                <KeyGenerator onKeyGenerated={refreshApiKeys} />

            </div>
        </>

    )
}

export default MyAPIKeys