import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify';
import Header from '../home/Header';
import { useLocation, useNavigate } from 'react-router-dom';
import './EditProfile.css';

const EditProfile = () => {
    const location = useLocation();
    const { profile } = location.state || {};
    const [formData, setFormData] = useState(profile || {});
    const navigate = useNavigate();
    const email = sessionStorage.getItem("user_email");

    useEffect(() => {
        if (!email) {
            navigate('/login');
            return;

        }
    }, [email]);
    
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    }
    const handleGoBack = () => {
        navigate(-1); // Navigate back to the previous page
    };
    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const server = process.env.REACT_APP_API_SERVER;
            const username = process.env.REACT_APP_API_USERNAME;
            const password = process.env.REACT_APP_API_PASSWORD;
            const credentials = btoa(`${username}:${password}`);
            const response = await fetch(`${server}/users/${profile.email}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Basic ${credentials}`,
                },
                body: JSON.stringify(formData),
            });
            if (response.ok) {
                toast.success('Profile updated successfully');
                // onSave(formData);
                navigate('/my-profile');
            } else {
                const errorMsg = await response.text();
                toast.error(errorMsg || "Failed to update profile.");
            }
        } catch (error) {
            toast.error(error.message || "Failed to update profile.");
        }
    };

    return (
        <>
            <Header />
            <div className='edit-profile-container'>
                <h2>Edit Profile</h2>
                <form onSubmit={handleSubmit}>
                    <div className='form-group'>
                        <label htmlFor='name'>Name</label>
                        <input
                            type='text'
                            name='name'
                            id='name'
                            value={formData.name || ''}
                            onChange={handleChange}
                        />
                    </div>
                    <div className='form-group'>
                        <label htmlFor='address'>Address:</label>
                        <input
                            type='text'
                            name='address'
                            id='address'
                            value={formData.address || ''}
                            onChange={handleChange}
                        />
                    </div>
                    <div className='form-group'>
                        <label htmlFor='phone'>Phone:</label>
                        <input
                            type='text'
                            id='phone'
                            name='phone'
                            value={formData.phone || ''}
                            onChange={handleChange}
                        />
                    </div>
                    <div className='form-group'>
                        <label htmlFor='company'>Company:</label>
                        <input
                            type='text'
                            id='company'
                            name='company'
                            value={formData.company || ''}
                            onChange={handleChange}
                        />
                    </div>
                    <div className='form-group'>

                    </div>
                    <div className='button-group'>
                        <button type='submit' className='update-button'>Update</button>
                        <button type='button' className='go-back-button' onClick={handleGoBack}>Go Back</button>
                    </div>
                </form>
            </div>
        </>
    )
}

export default EditProfile