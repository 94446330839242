import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import './Signup.css';

const Signup = () => {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [company, setCompany] = useState('');
    const [address, setAddress] = useState('');
    const [phone, setPhone] = useState('');
    const [password, setPassword] = useState('');
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    const handleSignup = async (e) => {
        e.preventDefault();
        if (!name || !email || !password || !phone) {
            toast.error("Please fill all required fields");
            return;
        }

        setLoading(true);
        try {
            const server = process.env.REACT_APP_API_SERVER;
            const response = await fetch(`${server}/signup`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    name: name,
                    email: email,
                    company: company,
                    address: address,
                    phone: phone,
                    password: password,
                }),
            });
            if (response.ok) {
                toast.success('Signup successful. Please check your email for verification instructions.');
                navigate('/login');
            } else {
                // Signup failed, display an error message.
                // Handle different HTTP status codes
                const errorMsg = await response.text();
                console.log("respo data " + errorMsg)
                switch (response.status) {
                    case 400:
                        toast.error(errorMsg || 'Bad Request: Please check your input.');
                        break;
                    case 401:
                        toast.error(errorMsg || 'Unauthorized: Access is denied.');
                        break;
                    case 403:
                        toast.error(errorMsg || 'Forbidden: You do not have permission.');
                        break;
                    case 404:
                        toast.error(errorMsg || 'Not Found: The requested resource was not found.');
                        break;
                    case 409:
                        toast.error(errorMsg || 'Conflict: The email address is already in use.');
                        break;
                    case 500:
                        toast.error(errorMsg || 'Internal Server Error: Please try again later.');
                        break;
                    default:
                        toast.error(errorMsg || 'An unexpected error occurred.');
                        break;
                    // toast.error(errorData.message || 'An error occurred during signup.');
                }
            }
        } catch (error) {
            console.error('Unexpected error:', error);
            toast.error(error.message);
        } finally {
            setLoading(false);
        }
    };


    return (
        <div className="signup-container">
            <form className='signup-form' onSubmit={handleSignup}>
                <h1 className='form__title'>GeoQuery Data</h1>
                <div>
                    <label>Name:</label>
                    <input
                        type="text"
                        placeholder="Enter your name"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        required
                    />
                </div>
                <div>
                    <label>Email:</label>
                    <input
                        type="email"
                        placeholder="Enter your email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                    />
                </div>
                <div>
                    <label>Password:</label>
                    <input
                        type="password"
                        placeholder="Enter your password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        required
                    />
                </div>
                <div>
                    <label>Company/Organization:</label>
                    <input
                        type="text"
                        placeholder="Enter your Company"
                        value={company}
                        onChange={(e) => setCompany(e.target.value)}
                    // required
                    />
                </div>
                <div>
                    <label>Address:</label>
                    <input
                        type="text"
                        placeholder="Enter your address"
                        value={address}
                        onChange={(e) => setAddress(e.target.value)}
                    // required
                    />
                </div>
                <div>
                    <label>Phone:</label>
                    <input
                        type="text"
                        placeholder="Enter your phone"
                        value={phone}
                        onChange={(e) => {
                            const value = e.target.value;
                            if (/^\d*$/.test(value)) setPhone(value); // Only allow digits
                        }}
                        maxLength="10" // Adjust length as per your requirements
                        required
                    />
                </div>

                <button className='form__button' type='submit' disabled={loading}>
                    {loading ? 'Loading...' : 'Sign Up'}
                </button>
                <p className='form__text signup-text'>
                    {/* Already have an account? <a href='/login' className='form__link'>Login</a> */}
                    Already have an account? <Link to="/login" className="form__link">Login</Link>
                </p>
            </form>
        </div>
    )
}

export default Signup