import logo from './logo.svg';
import './App.css';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Login from './pages/login/Login';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import Signup from './pages/signup/Signup';
import Reset from './pages/reset/Reset';
import Home from './pages/home/Home';
import ApiDocumentation from './pages/apidoc/ApiDocumentation';
import Header from './pages/home/Header';
import Pricing from './pages/pricing/Pricing';
import ContactUs from './pages/contact/ContactUs';
import AboutUs from './pages/about/AboutUs';
import MyProfile from './pages/profile/MyProfile';
import EditProfile from './pages/profile/EditProfile';
import MyAPIKeys from './pages/apikeygen/MyAPIKeys';
import OpenPricing from './pages/pricing/OpenPricing';
import PrivacyPolicy from './pages/privacy/PrivacyPolicy';
import Footer from './components/footer/Footer';

function App() {
  return (
    <Router>
      <div className="App">
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />

        <Routes>
          <Route exact path='/login' element={<Login />} />
          <Route exact path='/signup' element={<Signup />} />
          <Route exact path='/reset' element={<Reset />} />
          <Route exact path='/' element={<Home />} />
          <Route path='/pricing' element={<Pricing />} />
          <Route path='/op-pricing' element={<OpenPricing />} />
          <Route path='/contact' element={<ContactUs />} />
          <Route path='/about' element={<AboutUs />} />
          <Route path='/api-doc' element={<ApiDocumentation />} />
          <Route path='/my-profile' element={<MyProfile />} />
          <Route path='/update-profile' element={<EditProfile />} />
          <Route path='/my-api-keys' element={<MyAPIKeys />} />
          <Route path='/privacy-policy' element={<PrivacyPolicy />} />

        </Routes>
        <Footer />
      </div>
    </Router>

  );
}

export default App;
