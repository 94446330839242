import React, { useState } from 'react';
import './KeyGenerator.css';
import { useNavigate } from 'react-router-dom';

const KeyGenerator = ({onKeyGenerated }) => {
    const [apiKey, setApiKey] = useState('');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const navigate = useNavigate();

    const generateApiKey = async () => {
        setLoading(true);
        setError("");
        try {
            const server = process.env.REACT_APP_API_SERVER;
            const username = process.env.REACT_APP_API_USERNAME;
            const password = process.env.REACT_APP_API_PASSWORD;
            const credentials = btoa(`${username}:${password}`);

            const email=sessionStorage.getItem('user_email');
            if(!email){
                alert("Please login first");
                navigate('/login');
                return;

            }
            const response = await fetch(`${server}/api`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Basic ${credentials}`,
                },
                body: JSON.stringify({
                    email:email ,
                }),
            });
            if (response.ok) {
                // const data = await response.data;
                console.log("api key: ", response);
                setApiKey("API key generated successfully");
            } else if(response.status===400){
                setError("Session expired. Please Login again!");
            }
            else {
                setError('Failed to generate API key. Please try again');
            }
        } catch (error) {
            setError(`Unexpected error: ${error.message}`);
        } finally {
            setLoading(false);
        }
        onKeyGenerated(); 
    };

    return (
        <div className="card"> {/* Use 'card' class from CSS */}
            {/* <h2>API Key Generator</h2> */}
            <button
                className="button"
                onClick={generateApiKey}
                disabled={loading}
            >
                {loading ? 'Generating...' : 'Generate API Key'}
            </button>
            {apiKey && (
                <div className="api-key"> {/* Use 'api-key' class from CSS */}
                   {apiKey}
                </div>
            )}
            {error && (
                <div className="error">
                    {error}
                </div>
            )}
        </div>
    );
};

export default KeyGenerator;
