import React from 'react';
import Header from '../home/Header';
import './OpenPricing.css'; // Importing CSS for styling
import { useNavigate } from 'react-router-dom';

const OpenPricing = () => {
    const navigate = useNavigate();
    const pricingOptions = [
        {
            title: "Free Trial",
            price: "A$0",
            features: [
                "1000 request per day",
                "Only one API key",
                "Basic Support",

            ],
            buttonText: "Start Free Trial",
        }, {
            title: "Extra Small Plan",
            price: "A$50/month",
            features: [
                "10,000 request per day",
                "Multiple API keys"
            ],
            buttonText: "Choose Extra Small",
        },
        {
            title: "Small Plan",
            price: "A$120/month",
            features: [
                "30,000 request per day",
                "Multiple API keys"
            ],
            buttonText: "Choose Small",
        },
        {
            title: "Medium Plan",
            price: "A$300/month",
            features: [
                "100k request per day",
                "Multiple API keys"
            ],
            buttonText: "Choose Medium",
        },
        {
            title: "Large Plan",
            price: "A$900/month",
            features: [
                "350k request per day",
                "Multiple API keys"
                // "Dedicated Support",
                // "Custom Solutions",
            ],
            buttonText: "Choose Large",
        },
    ];
    const handleButtonClick = (path) => {
        // history.push(path);  // For React Router v5
        navigate("/login"); // For React Router v6
    };

    return (
        <div>
            <Header />
            <div className="pricing-section">
                <h1>Pricing Plans</h1>
                <div className="pricing-cards">
                    {pricingOptions.map((option, index) => (
                        <div className="pricing-card" key={index}>
                            <h2>{option.title}</h2>
                            <p className="price">{option.price}</p>

                            <button className="pricing-button"
                                aria-label={`Select ${option.title}`}
                                onClick={() => handleButtonClick()}
                            >
                                {option.buttonText}
                            </button>
                            <div className='listing-div'>

                                <p className='title-feature'>This includes:</p>
                                <ul>
                                    {option.features.map((feature, i) => (
                                        <li key={i}>{feature}</li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
}

export default OpenPricing;
