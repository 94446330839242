import React, { useEffect, useState } from 'react'
import './MyProfile.css';
import { toast } from 'react-toastify';
import Header from '../home/Header';
import { useNavigate } from 'react-router-dom';

const MyProfile = () => {
    const [profile, setProfile] = useState(null);
    const [loading, setLoading] = useState(true);
    const email = sessionStorage.getItem("user_email");
    const navigate = useNavigate();

    useEffect(() => {
        if (!email) {
            navigate('/login');
            return;

        }
        const fetchProfile = async () => {
            try {
                const server = process.env.REACT_APP_API_SERVER;
                const username = process.env.REACT_APP_API_USERNAME;
                const password = process.env.REACT_APP_API_PASSWORD;
                const credentials = btoa(`${username}:${password}`);
                const response = await fetch(`${server}/users/${email}`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Basic ${credentials}`
                    },
                });
                setLoading(false); // Set loading to false after fetching

                if (response.ok) {
                    const data = await response.json();
                    setProfile(data);
                } else {
                    const errorMsg = await response.text();
                    if (response.status === 404) {
                        toast.error('Profile not found.');
                    } else {
                        toast.error(errorMsg || 'Failed to fetch profile data.');
                    }
                }
            } catch (error) {
                setLoading(false); // Ensure loading is false on error
                toast.error(error.message || 'Failed to fetch profile data.');
            }
        };
        fetchProfile();
    }, [email, navigate]);
    
    const handleEditProfile = () => {
        navigate('/update-profile', { state: { profile } });
    };
    return (
        <>
            <Header />

            <div className='profile-container'>
                <h2>My Profile</h2>
                {profile ? (
                    <div className="profile-details">
                        <p><strong>Name:</strong> {profile.name}</p>
                        <p><strong>Email:</strong> {profile.email}</p>
                        <p><strong>Address:</strong> {profile.address}</p>
                        <p><strong>Phone:</strong> {profile.phone}</p>
                        {profile.company &&
                            <p><strong>Company:</strong> {profile.company}</p>
                        }
                        <p><strong>Status:</strong> {profile.status}</p>
                        <p><strong>Added On:</strong> {profile.addedOn}</p>
                        {profile.lastUpdatedAt &&
                            <p><strong>Last Updated:</strong> {profile.lastUpdatedAt}</p>
                        }
                        {/* <Link to={{
                            pathname: "/update-profile",
                            state: { profile }
                        }}> */}
                        <button onClick={handleEditProfile}>Edit Profile</button>
                        {/* </Link>       */}
                    </div>
                ) : (
                    <p>Please log in to view your profile.</p>
                )}
            </div>
        </>
    )
}

export default MyProfile