import React from 'react';
import { Link } from 'react-router-dom';
import './Footer.css';
import logo from '../../assets/logo_transparent-c.png'
// import { FaArrowUp } from 'react-icons/fa';
const Footer = () => {
    return (
        <footer className="footer">
            <div className="footer-container">

                {/* Quick Links Section */}
                <div className="footer-section">
                    <h4>Quick Links</h4>
                    <ul className="footer-links">
                        <li><Link to="/about">About Us</Link></li>
                        <li><Link to="/op-pricing">Pricing</Link></li>
                        <li><Link to="/api-doc">API Documentations</Link></li>
                        <li><Link to="/contact">Contact Us</Link></li>
                        <li>  <Link to="/privacy-policy">Privacy Policy</Link>
                        </li>
                    </ul>
                </div>

                {/* Contact Details Section */}
                <div className="footer-section">
                    <h4>Contact Us</h4>
                    <p>Email: <a href="mailto:info@geoquerydata.com">info@geoquerydata.com</a></p>
                    <p>Mail:3 Ross Pl, Wetherill Park NSW 2164
                    </p>
                </div>

                {/* Copyright Section */}
                <div className="footer-bottom">
                    <div className="footer-logo">
                        <img src={logo} alt="Geoquery Data Logo" />
                    </div>
                    <p>&copy; {new Date().getFullYear()} Geoquery Data. All rights reserved.</p>
                    <a href="#top" className="back-to-top" aria-label="Scroll to top">
                        Back to Top
                        <i className="fas fa-arrow-up arrow-icon"></i>
                    </a>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
